export const MEMO_FILES_FIELDS_NAMES = {
    BULLET_IMAGE: "bullet-image",
    COVER_IMAGE: "cover-image",
    THUMBNAIL_IMAGE: "thumbnail-image",
    MEMO_IMAGE: "memo-image"
}

export const PAYMENT_STATES = {
    INVITATION: 0,
    PENDING: 1,
    ACCEPTED: 2
}

export const TOPIC_STATUS = {
    PENDING: 1,
    APPROVE: 2,
    REJECT: 3
}
