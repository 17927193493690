<template>
    <div class="web-app-create">
        <convert-multipart-confirmation @convert-to-multipart="convertToMultipart" />
        <fullscreen-loader v-if="isLoading" />
        <transition name="slide-top">
            <drafts-list v-if="showDraftsList" @toggle-drafts-list="toggleDraftsList" @delete-memo="handleDeletedMemo" />
        </transition>
        <transition name="slide-up" mode="in-out">
            <div v-if="showMemoPreview" class="preview-memo">
                <div class="preview-memo-overlay" />
                <div class="preview-memo-header">
                    <div class="container d-flex align-items-center justify-content-between">
                        <div class="go-back" @click="showMemoPreview = false">
                            <img src="@assets/img/icons/left-chevron.svg" width="10px">
                            <span>Go back to Composer</span>
                        </div>
                        <span class="body-text-sb">Preview Mode</span>
                    </div>
                </div>
                <preview-memo
                    is-preview-mode
                    :memo-preview="memo"
                    @is-loading="(value) => isLoading = value"
                />
            </div>
        </transition>
        <div class="container" :class="{ 'is-multipart' : !isSingleMemod }">
            <form-wizard ref="create_memod_wizard" @on-change="handleTabChange">
                <tab-content>
                    <div class="steps step-1">
                        <bullets-composer
                            ref="bullets_composer"
                            v-model="currentPart"
                            :bullet-display-type="memo.display_type"
                            :is-draft-memo="isDraftMemo"
                            :is-multipart-memo="!isSingleMemod"
                            :is-bullet-step-valid="isBulletStepValid"
                            @set-display-type="(displayType) => memo.display_type = displayType"
                            @associate-images-to-memo="associateImagesToMemo"
                            @editor-loaded="editorLoaded"
                            @is-loading="(value) => isLoading = value"
                            @go-next-step="goNextStep"
                            @save-draft="saveDraft(openDraftsList = true)"
                            @show-multipart-selector="showMultiPartSelector = true"
                        />
                        <transition name="fade" mode="out-in">
                            <multipart-selector
                                v-if="!isSingleMemod"
                                :class="{ 'show-multipart-selector' : showMultiPartSelector }"
                                :memo-title="memo.title"
                                :memo-parts="memo.parts"
                                :are-all-parts-valid="areAllPartsValid"
                                @on-title-change="(value) => memo.title = value"
                                @change-current-part="(newCurrentPartNumber) => currentPartNumber = newCurrentPartNumber"
                                @add-part="addPart"
                                @delete-part="deletePart"
                                @set-editor-content="setEditorContent"
                                @hide-multipart-selector="showMultiPartSelector = false"
                            />
                        </transition>
                    </div>
                </tab-content>
                <tab-content>
                    <div class="steps step-2">
                        <memod-form
                            ref="memod_form"
                            v-bind.sync="memo"
                            :memo-images="memoImagesForCarousel"
                            :is-draft-memo="isDraftMemo"
                            @go-prev-step="wizard.prevTab()"
                            @memo-image-uploaded="memoImageUploaded"
                            @mark-cover-image="markCoverImage"
                            @delete-image="deleteImage"
                            @save-memo="saveMemo(publishMemo = true)"
                            @show-memo-preview="showMemoPreview = true"
                        />
                    </div>
                </tab-content>
            </form-wizard>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { uniqBy, some } from "lodash";
import { MEMO_FILES_FIELDS_NAMES } from "@/utils/constants";

// 1 MINUTE
const AUTOSAVE_TIME_IN_MS = 1 * 60 * 1000;

export default {
    name: "WebAppCreate",
    components: {
        FormWizard,
        TabContent,
        BulletsComposer: () => import(/* webpackChunkName: "bullets-composer" */ "./bullets-composer/"),
        MemodForm: () => import(/* webpackChunkName: "memod-form" */ "./memod-form"),
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader" */ "@c/molecules/fullscreen-loader.vue"),
        DraftsList: () => import(/* webpackChunkName: "drafts-list" */ "./drafts-list"),
        MultipartSelector: () => import(/* webpackChunkName: "multipart-selector" */ "./multipart-selector"),
        ConvertMultipartConfirmation: () => import(/* webpackChunkName: "convert-multipart-confirmation" */ "@c/organisms/modals/convert-multipart-confirmation"),
        PreviewMemo: () => import(/* webpackChunkName: "preview-memo" */ "@v/web-app/memod-reader/")
    },
    data() {
        return {
            showMultiPartSelector: false,
            imageResizerUrl: process.env.VUE_APP_IMAGE_RESIZER_URL,
            isLoading: true,
            saveAsDraftInterval: null,
            currentPartNumber: 0,
            showMemoPreview: false,
            isDeletedDraft: false,
            reloadAfterSaveDraft: false,
            memo: {
                id: null,
                files: [],
                type: { id: 1, name: "Single" },
                display_type: { id: "1", name: "bullet" },
                source_link: "",
                title: "",
                source_title: "",
                topics: [],
                parts: [
                    {
                        title: "",
                        bullets: [
                            {
                                prettyText:"<p><br></p>",
                                rawText: ""
                            }
                        ]
                    }
                ],
                is_original: 0,
                is_published: 0,

                // Harcoded properties needed in the backend for some reason
                is_featured:0,
                is_premium:0,
                is_public:1,
                location: {
                    "latitude": "0.00000000",
                    "longitude": "0.00000000",
                    "name": ""
                }
            }
        };
    },
    computed: {
        ...mapState({
            userData: state => state.User.data,
            showDraftsList: state => state.Application.showDraftsList
        }),
        isSingleMemod() {
            return this.memo.type.name == "Single";
        },
        currentPart: {
            get() {
                return this.memo.parts[this.currentPartNumber];
            },
            set(newValue) {
                this.memo.parts.splice(this.currentPartNumber, 1, { ...this.memo.parts[this.currentPartNumber], ...newValue })
            }
        },
        memoImagesForCarousel() {
            const images = uniqBy(this.memo.files, (file) => file.name)
                .filter((file) => (file.field_name !== MEMO_FILES_FIELDS_NAMES.BULLET_IMAGE) && (file.field_name !== MEMO_FILES_FIELDS_NAMES.THUMBNAIL_IMAGE) && !file.is_deleted);
            const carouselMaxWidth = 650;
            return images.map(image => {
                return {
                    field_name: image.field_name,
                    filesystem_id: image.filesystem_id,
                    x1: `${this.imageResizerUrl}/${image.attributes.unique_name}?w=${carouselMaxWidth * 1}&fit=outside`,
                    x2: `${this.imageResizerUrl}/${image.attributes.unique_name}?w=${carouselMaxWidth * 2}&fit=outside`,
                    x3: `${this.imageResizerUrl}/${image.attributes.unique_name}?w=${carouselMaxWidth * 3}&fit=outside`
                }
            })
        },
        isEditMemo() {
            return Boolean(this.$route.params.id);
        },
        wizard() {
            return this.$refs.create_memod_wizard;
        },
        doAllPartsHaveTitle() {
            return !some(this.memo.parts, ["title", ""]);
        },
        // doAllPartsHaveAtLeastOneBullet() {
        //     const array = [];
        //     this.memo.parts.forEach((part, index) => {
        //         array.push(this.memo.parts[index].bullets.some(this.isNotEmptyRawText))
        //     })
        //     return array.every(element => Boolean(element));
        // },
        areAllPartsValid() {
            // return this.doAllPartsHaveTitle && this.doAllPartsHaveAtLeastOneBullet;
            return this.doAllPartsHaveTitle;
        },
        isBulletStepValid() {
            return this.areAllPartsValid && (this.isSingleMemod ? true : this.memo.title != "");
        },
        isDraftMemo() {
            return Boolean(!Number(this.memo.is_published));
        }
    },
    watch: {
        "$route": {
            handler(newVal, oldVal) {
                if (oldVal.name == "web-app-edit" && newVal.name == "web-app-create") {
                    window.location.reload();
                    return;
                }

                if (newVal.params.id != oldVal.params.id) {
                    this.fetchMemo();
                }
            }
        },
        currentPartNumber() {
            this.setEditorContent();
        }
    },
    created() {
        window.addEventListener("beforeunload", this.beforeWindowUnload);
    },
    mounted() {
        if (!this.isEditMemo && this.isDraftMemo) {
            this.autoSaveDraft();
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isDraftMemo) {
            this.saveDraft();
            next();
        } else {
            next();
        }
    },
    beforeDestroy() {
        clearInterval(this.saveAsDraftInterval);
        window.removeEventListener("beforeunload", this.beforeWindowUnload);
    },
    methods: {
        async reloadComposer() {
            window.removeEventListener("beforeunload", this.beforeWindowUnload);
            this.reloadAfterSaveDraft = true;
            this.saveDraft();
        },
        autoSaveDraft() {
            this.saveAsDraftInterval = setInterval(() => {
                this.saveDraft();
            }, AUTOSAVE_TIME_IN_MS);
        },
        saveDraft(openDraftsList = false) {
            if (this.isBulletStepValid) {
                const publishMemo = false;
                this.saveMemo(publishMemo, openDraftsList);
            } else if (openDraftsList) {
                this.toggleDraftsList(true);
            }
        },
        editorLoaded() {
            if (this.isEditMemo) {
                this.fetchMemo();
            } else {
                this.setEditorContent();
            }
        },
        setEditorContent() {
            this.$refs.bullets_composer.setContent({ ...this.currentPart, bulletsType: this.memo.display_type, activePart: this.isSingleMemod ? 0 : (this.currentPartNumber + 1) });
        },
        goNextStep() {
            this.wizard.nextTab();
        },
        saveMemo(publishMemo = false, openDraftsList = false) {
            this.memo.is_published = Number(publishMemo);

            if (this.memo.is_published) {
                this.isLoading = true;
            }

            if (this.memo.type.name == "Single") {
                this.memo.title = this.memo.parts[0].title;
            }

            axiosV2({
                url: "/memos",
                method: "POST",
                data: this.memo
            }).then(({ data }) => {
                if (Number(data.is_published)) {
                    this.$notify({
                        group: "app-notifications",
                        type: "success",
                        text: "Memo published successfully",
                        duration: 3000
                    });

                    this.$router.push({
                        name: "memod-reader",
                        params: {
                            id: data.id,
                            slug: data.slug,
                            writer: `${data.writer.displayname}`
                        }
                    });
                } else {
                    this.memo.id = data.id;
                    this.memo.files = data.files;

                    if (this.reloadAfterSaveDraft) {
                        window.location.reload();
                    }

                    if (openDraftsList) {
                        this.toggleDraftsList(true);
                    }

                    this.$notify({
                        group: "app-notifications",
                        type: "success",
                        text: "Memo draft saved successfully",
                        duration: 3000
                    });
                }
            }).catch(error => {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: error.response.data.errors.message,
                    duration: 3000
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        markCoverImage(filesystemId) {
            const currentCoverImageIndex = this.memo.files.findIndex(file => file.field_name == MEMO_FILES_FIELDS_NAMES.COVER_IMAGE);
            const newCoverImageIndex = this.memo.files.findIndex(file => file.filesystem_id == filesystemId);

            if (currentCoverImageIndex >= 0 && newCoverImageIndex >= 0) {
                this.memo.files[currentCoverImageIndex].field_name = MEMO_FILES_FIELDS_NAMES.MEMO_IMAGE;
            }

            this.memo.files[newCoverImageIndex].field_name = MEMO_FILES_FIELDS_NAMES.COVER_IMAGE;
        },
        deleteImage(filesystemId) {
            const imageToDeleteIndex = this.memo.files.findIndex(file => file.filesystem_id == filesystemId);

            // If the image to be deleted from the carousel has never been sent to the backend and does not have and id
            // we just remove it from the array, otherwise if the image actually have and id it means is exist in the backend
            // so we mark it for deletion
            if (this.memo.files[imageToDeleteIndex].id) {
                this.memo.files[imageToDeleteIndex].is_deleted = 1;
            } else {
                this.memo.files.splice(imageToDeleteIndex, 1);
            }

            // After deleting the image in above logic, check if there is no cover image and if there's another image in the
            // carousel to set a new cover image
            const coverImage = this.memo.files.find(file => file.field_name == MEMO_FILES_FIELDS_NAMES.COVER_IMAGE);
            const memoImageIndex = this.memo.files.findIndex(file => file.field_name == MEMO_FILES_FIELDS_NAMES.MEMO_IMAGE);
            if ((!coverImage || coverImage.is_deleted) && (memoImageIndex != -1)) {
                this.markCoverImage(this.memo.files[memoImageIndex].filesystem_id);
            }

            // and finally we need to force update carousel images
            this.$refs.memod_form.$refs.memo_images_carousel.$refs.memoImagesSwiper.$swiper.update();
        },
        memoImageUploaded(file) {
            // When a new image is uploaded we check if the memo already have a cover image and if don't then we mark
            // the just uploaded image as a cover-image
            const coverImageIndex = this.memo.files.findIndex(file => file.field_name == MEMO_FILES_FIELDS_NAMES.COVER_IMAGE);
            if (coverImageIndex == -1) {
                file.field_name = MEMO_FILES_FIELDS_NAMES.COVER_IMAGE;
            }

            this.associateImagesToMemo(file);
        },
        associateImagesToMemo(file) {
            this.memo.files.push({
                ...file,
                filesystem_id: Number(file.id),
                id: null
            });
        },
        fetchMemo() {
            axiosV2.get(`/memos/${this.$route.params.id}`).then(({ data }) => {
                const loggedUserId = Number(this.userData.id);
                const memodOwnerId = Number(data.writer.id);

                if (loggedUserId == memodOwnerId || data.can_edit) {
                    const memo = data;
                    for (var i = 0; i < memo.files.length; i++) {
                        memo.files[i].is_deleted = memo.files[i].is_deleted ? memo.files[i].is_deleted : 0;
                    }

                    this.memo = memo;
                    this.setEditorContent();
                } else {
                    this.$router.push({ name: "web-app-home" });
                }
            }).catch((error) => {
                console.log(error.response.data.message);
            })
        },
        toggleDraftsList(value) {
            this.$store.dispatch("Application/toggleDraftsList", value);
        },
        beforeWindowUnload(e) {
            if (this.isDraftMemo && this.isBulletStepValid && !this.isDeletedDraft && !this.showDraftsList) {
                this.saveDraft();
                e.preventDefault();
                e.returnValue = "";
            }
        },
        handleTabChange() {
            if (this.memoImagesForCarousel.length) {
                setTimeout(() => {
                    this.$refs.memod_form.$refs.memo_images_carousel.$refs.memoImagesSwiper.$swiper.update();
                }, 300);
            }
        },
        addPart() {
            this.memo.parts.push({
                title: "",
                bullets: [
                    {
                        prettyText:"<p><br></p>",
                        rawText: ""
                    }
                ]
            });

            this.currentPartNumber = this.memo.parts.length - 1;
        },
        deletePart(index) {
            this.memo.parts.splice(index, 1);
            this.currentPartNumber = 0;
        },
        convertToMultipart() {
            this.$modal.hide("convert-multipart-confirmation");

            this.addPart();
            this.currentPartNumber = 1;

            this.memo.type = {
                name: "Serialized",
                id: 2
            }

            this.setEditorContent();
        },
        isNotEmptyRawText(bullet) {
            return bullet.rawText != "";
        },
        handleDeletedMemo(deletedMemoId) {
            if (this.memo.id == deletedMemoId) {
                this.isDeletedDraft = true;
                window.location.reload();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.web-app-create {
    .container {
        @media(min-width: $md) {
            max-width: 680px;
        }

        @media(min-width: 992px) and (max-width: 1270px) {
            &.is-multipart {
                margin-left: 5vw;
            }
        }

        @media(max-width: $md) {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .loader {
        z-index: 10;
        background-color: rgba(29, 29, 32, .7);
        backdrop-filter: blur(6px);

        /deep/ img {
            width: 100px !important;
        }
    }

    .vue-form-wizard {
        padding: 0;

        /deep/ .wizard-header,
        /deep/ .wizard-progress-with-circle,
        /deep/ .wizard-nav.wizard-nav-pills,
        /deep/ .wizard-card-footer {
            display: none;
        }

        /deep/ .wizard-tab-content {
            padding: 0;
            display: flex;

            .wizard-tab-container {
                display: block;
                width: 100%;
                /* animation: fadeInLeft 0.3s; */
            }
        }
    }

    .steps {
        padding-top: 5vh;

        @media(max-width: $lg) {
            padding-top: 2vh;
        }

        &.step-1 {
            position: relative;

            .multipart-selector {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 3;
                transform: translateX(calc(100% + 30px));
                padding-top: 12vh;
                height: calc(100vh - 5vh - 65px);

                @media(max-width: $lg) {
                    position: fixed;
                    right: -330px;
                    top: 0;
                    width: 330px;
                    transform: initial;
                    background-color: #1C1C1E;
                    padding-left: 20px;
                    padding-right: 20px;
                    z-index: 20;
                    padding-top: 6vh;
                    height: 100%;
                    transition: right .3s;

                    &.show-multipart-selector {
                        right: 0px;
                    }

                    /deep/ .close-multipart-selector {
                        display: block;
                    }
                }
            }
        }
    }

    .preview-memo {
        position: absolute;
        z-index: 5;
        width: 100%;
        background-color: #0F0F10;
        min-height: calc(100vh - 65px);

        .preview-memo-overlay {
            position: fixed;
            z-index: 6;
            left: 0;
            top: 135px;
            right: 0;
            bottom: 0;
        }

        .preview-memo-header {
            padding: 25px 15px;
            background-color: black;

            .go-back {
                font-size: 12px;
                line-height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                span {
                    margin-left: 15px;
                }
            }
        }
    }

}
</style>
